import "./Terms.scss";

export default function TermsPage({ type }) {
    const PreteethAIPro = () => (
        <>
            <h2>TERMS OF USE</h2>
            <span style={{ color: "#595959" }}>Updated at 2024-11-20</span>

            <h3>AGREEMENT TO OUR LEGAL TERMS</h3>
            <p>
                Welcome to Preteeth AI, Inc. ("Company," "we," "us," "our"). These Terms of Use ("Terms") constitute a
                legally binding agreement between you and Preteeth AI, Inc., governing your access to and use of the
                following services:
            </p>
            <ul>
                <li>
                    <span>Preteeth AI Pro</span>, An AI-powered smile design application that allows users to simulate
                    aesthetic dental outcomes based on uploaded images.
                </li>
                <li>
                    <span>Dentscape</span>, An AI-powered web-based CAD software that provides tools for dental
                    professionals to automate dental prosthetic design and customization.
                </li>
            </ul>
            <p>
                These applications, along with related products, services, and websites (collectively, the "Services"),
                are subject to the terms outlined below. By accessing the Services, you confirm that you have read,
                understood, and agree to these Terms.
            </p>
            <span>If you do not agree, you must not use the Services.</span>

            <div className="divider" />

            <h3>TABLE OF CONTENTS</h3>
            <ol>
                <li>
                    <a href="#definitions">DEFINITIONS</a>
                </li>
                <li>
                    <a href="#services">OUR SERVICES</a>
                </li>
                <li>
                    <a href="#intellectual">INTELLECTUAL PROPERTY RIGHTS</a>
                </li>
                <li>
                    <a href="#representations">USER REPRESENTATIONS</a>
                </li>
                <li>
                    <a href="#registration">USER REGISTRATION</a>
                </li>
                <li>
                    <a href="#prohibited">PROHIBITED ACTIVITIES</a>
                </li>
                <li>
                    <a href="#purchase">PURCHASES, PAYMENTS, AND SUBSCRIPTIONS</a>
                </li>
                <li>
                    <a href="#contributions">CONTRIBUTIONS AND USER DATA</a>
                </li>
                <li>
                    <a href="#softwareLicense">SOFTWARE LICENSE</a>
                </li>
                <li>
                    <a href="#servicesManagement">SERVICES MANAGEMENT</a>
                </li>
                <li>
                    <a href="#disclaimerAndLimitationLiability">DISCLAIMERS AND LIMITATIONS OF LIABILITY</a>
                </li>
                <li>
                    <a href="#indemnification">INDEMNIFICATION</a>
                </li>
                <li>
                    <a href="#governingLaw">GOVERNING LAW AND DISPUTE RESOLUTION</a>
                </li>
                <li>
                    <a href="#miscellaneous">MISCELLANEOUS</a>
                </li>
                <li>
                    <a href="#contact">CONTACT US</a>
                </li>
            </ol>

            <div className="divider" />

            <h3 id="definitions">1. DEFINITIONS</h3>
            <ul>
                <li>
                    <span>Preteeth AI Pro:</span> An AI-powered application for simulating dental aesthetics using
                    facial or dental images.
                </li>
                <li>
                    <span>Dentscape:</span> A web-based CAD software enabling dental professionals to design dental
                    restorations with AI-driven workflows.
                </li>
                <li>
                    <span>Services:</span> All products, applications, websites, and related services provided by
                    Preteeth AI, Inc.
                </li>
                <li>
                    <span>Content:</span> All software, designs, trademarks, and materials provided through the
                    Services.
                </li>
                <li>
                    <span>Users:</span> Individuals or entities accessing or using the Services.
                </li>
            </ul>

            <div className="divider" />

            <h3 id="services">2. OUR SERVICES</h3>
            <p>The Services provide advanced tools for dental professionals, including:</p>
            <ul>
                <li>
                    <span>Preteeth AI Pro:</span> Simulating smile aesthetics for informational purposes only.
                </li>
                <li>
                    <span>Dentscape:</span> Automating dental restoration design for professional use.
                </li>
            </ul>
            <p>
                Simulated images and restoration designs are for <span>reference purposes only</span>. They are not
                medical advice or diagnostics, and final decisions must be made by licensed dental professionals.
            </p>
            <p>
                The Services comply with privacy regulations such as the{" "}
                <span>General Data Protection Regulation (GDPR)</span> and the{" "}
                <span>California Consumer Privacy Act (CCPA)</span>. Personal data is processed securely in compliance
                with applicable laws.
            </p>

            <div className="divider" />

            <h3 id="intellectual">3. INTELLECTUAL PROPERTY RIGHTS</h3>
            <h4>Ownership of Content</h4>
            <p>
                All Content provided through the Services is owned by or licensed to Preteeth AI, Inc. and is protected
                by applicable intellectual property laws.
            </p>
            <h4>License to Use the Services</h4>
            <p>
                We grant you a <span>non-exclusive, revocable, limited license</span> to use the Services for lawful
                purposes in accordance with these Terms. Unauthorized use, including copying, modifying, or reverse
                engineering the software, is prohibited.
            </p>

            <div className="divider" />

            <h3 id="representations">4. USER REPRESENTATIONS</h3>
            <p>By using the Services, you represent and warrant that:</p>
            <ol>
                <li>You are at least 18 years old.</li>
                <li>You will provide accurate and up-to-date registration information.</li>
                <li>You will not use the Services for unauthorized or illegal purposes.</li>
                <li>
                    You understand that AI-generated designs are for reference purposes and require professional
                    interpretation.
                </li>
            </ol>

            <div className="divider" />

            <h3 id="registration">5. USER REGISTRATION</h3>
            <p>
                To access certain features, you must create an account. You are responsible for safeguarding your
                account credentials. You agree to provide accurate and current information during registration. We
                reserve the right to suspend or terminate accounts violating these Terms.
            </p>

            <div className="divider" />

            <h3 id="prohibited">6. PROHIBITED ACTIVITIES</h3>
            <p>You agree not to:</p>
            <ol>
                <li>Reverse engineer, copy, or distribute any part of the Services.</li>
                <li>Use the Services for unauthorized or illegal purposes.</li>
                <li>Submit false, misleading, or malicious content.</li>
                <li>Interfere with or disrupt the security or functionality of the Services.</li>
                <li>Use automated tools (e.g., bots, scrapers) to access the Services.</li>
            </ol>
            <p>Violating these terms may result in suspension or termination of your access.</p>

            <div className="divider" />

            <h3 id="purchase">7. PURCHASES, PAYMENTS, AND SUBSCRIPTIONS</h3>
            <h4>Preteeth AI Pro</h4>
            <ul>
                <li>
                    Subscriptions are billed monthly or annually and are <span>non-refundable</span>, except where
                    required by applicable law.
                </li>
                <li>
                    Subscriptions automatically renew unless canceled before the renewal date. Cancellation takes effect
                    at the end of the current billing cycle.
                </li>
            </ul>
            <h4>Dentscape</h4>
            <ul>
                <li>
                    Operates on a credit-based system. Purchased credits are <span>non-refundable</span> but remain
                    valid as specified.
                </li>
                <li>
                    Users receive complimentary credits upon registration. Additional credits must be purchased as
                    needed.
                </li>
            </ul>
            <p>
                <span>Important Notice:</span> All purchases and subscriptions are subject to these Terms of Use and any
                additional terms presented at the point of sale. By making a purchase, you agree to these terms.
            </p>

            <div className="divider" />

            <h3 id="contributions">8. CONTRIBUTIONS AND USER DATA</h3>
            <p>
                By submitting data or feedback to the Services, you grant Preteeth AI, Inc. a license to process and use
                this information to improve the Services. Users retain ownership of their data but grant us a license
                for operational purposes.
            </p>
            <p>
                For more details, refer to our Privacy Policy at{" "}
                <a href="https://dentscape.ai/privacy" target="_blank">
                    https://dentscape.ai/privacy
                </a>
                .
            </p>

            <div className="divider" />

            <h3 id="softwareLicense">9. SOFTWARE LICENSE</h3>
            <p>
                We grant you a non-exclusive, revocable license to use the software associated with the Services. You
                may not decompile, modify, or reverse-engineer any part of the software without prior written consent.
            </p>

            <div className="divider" />

            <h3 id="servicesManagement">10. SERVICES MANAGEMENT</h3>
            <p>
                We reserve the right to modify, suspend, or discontinue any part of the Services without notice. While
                we strive for uninterrupted access, availability cannot be guaranteed at all times.
            </p>

            <div className="divider" />

            <h3 id="disclaimerAndLimitationLiability">11. DISCLAIMERS AND LIMITATIONS OF LIABILITY</h3>
            <ul>
                <li>The Services are provided "as-is" and "as-available."</li>
                <li>
                    Preteeth AI, Inc. disclaims liability for indirect or consequential damages arising from your use of
                    the Services.
                </li>
            </ul>
            <p>
                Our liability is limited to the lesser of the amount you paid for the Services in the 12 months
                preceding the claim or $100.
            </p>

            <div className="divider" />

            <h3 id="indemnification">12. INDEMNIFICATION</h3>
            <p>
                You agree to indemnify and hold Preteeth AI, Inc. harmless from any claims, damages, or liabilities
                arising from:
            </p>
            <ul>
                <li>Your use of the Services.</li>
                <li>Breaches of these Terms.</li>
                <li>Violations of intellectual property rights or applicable laws.</li>
            </ul>

            <div className="divider" />

            <h3 id="governingLaw">13. GOVERNING LAW AND DISPUTE RESOLUTION</h3>
            <p>
                These Terms are governed by the laws of California, United States, without regard to conflict-of-law
                principles. Disputes will be resolved exclusively in the courts of San Francisco, California.
            </p>

            <div className="divider" />

            <h3 id="miscellaneous">14. MISCELLANEOUS</h3>
            <ul>
                <li>These Terms constitute the entire agreement between you and Preteeth AI, Inc.</li>
                <li>If any provision is found to be unenforceable, the remaining provisions remain in effect.</li>
                <li>Our failure to enforce any part of these Terms does not constitute a waiver of our rights.</li>
            </ul>

            <div className="divider" />

            <h3 id="contact">15. CONTACT US</h3>
            <p>If you have questions or concerns, contact us at:</p>
            <p>
                Preteeth AI Co., Ltd.
                <br />
                166 Geary St, STE 1500 #1709
                <br />
                San Francisco, CA 94114, US
                <br />
                Email:{" "}
                <a
                    href="mailto:info@dentscape.ai"
                    className="__cf_email__"
                    data-cfemail="47342235312e242207373522332222332f6924282a"
                >
                    info@dentscape.ai
                </a>
            </p>
        </>
    );

    const SmileCare = () => (
        <>
            <h2>Terms & Conditions</h2>
            <p style={{ color: "#595959" }}>Updated at August 16th, 2023</p>

            <h3>General Terms</h3>
            <p>
                By accessing and placing an order with SmileCare, you confirm that you are in agreement with and bound
                by the terms of service contained in the Terms & Conditions outlined below. These terms apply to the
                entire website and any email or other type of communication between you and SmileCare.
            </p>
            <p>
                Under no circumstances shall SmileCare team be liable for any direct, indirect, special, incidental or
                consequential damages, including, but not limited to, loss of data or profit, arising out of the use, or
                the inability to use, the materials on this site, even if SmileCare team or an authorized representative
                has been advised of the possibility of such damages. If your use of materials from this site results in
                the need for servicing, repair or correction of equipment or data, you assume any costs thereof.
            </p>
            <p>
                SmileCare will not be responsible for any outcome that may occur during the course of usage of our
                resources. We reserve the rights to change prices and revise the resources usage policy in any moment.
            </p>

            <h3>License</h3>
            <p>
                SmileCare grants you a revocable, non-exclusive, non-transferable, limited license to download, install
                and use the website strictly in accordance with the terms of this Agreement.
            </p>
            <p>
                These Terms & Conditions are a contract between you and SmileCare (referred to in these Terms &
                Conditions as "SmileCare", "us", "we" or "our"), the provider of the SmileCare website and the services
                accessible from the SmileCare website (which are collectively referred to in these Terms & Conditions as
                the "SmileCare Service").
            </p>
            <p>
                You are agreeing to be bound by these Terms & Conditions. If you do not agree to these Terms &
                Conditions, please do not use the SmileCare Service. In these Terms & Conditions, "you" refers both to
                you as an individual and to the entity you represent. If you violate any of these Terms & Conditions, we
                reserve the right to cancel your account or block access to your account without notice.
            </p>

            <h3>Definitions and key terms</h3>
            <p>
                To help explain things as clearly as possible in this Terms & Conditions, every time any of these terms
                are referenced, are strictly defined as:
            </p>
            <ul>
                <li>
                    Cookie: small amount of data generated by a website and saved by your web browser. It is used to
                    identify your browser, provide analytics, remember information about you such as your language
                    preference or login information.
                </li>
                <li>
                    Company: when this terms mention “Company,” “we,” “us,” or “our,” it refers to Preteeth AI Co.,
                    Ltd., (11F., No. 87, Songjiang Rd., Zhongshan Dist., Taipei City, Taiwan), that is responsible for
                    your information under this Terms & Conditions.
                </li>
                <li>Country: where SmileCare or the owners/founders of SmileCare are based, in this case is Taiwan</li>
                <li>
                    Device: any internet connected device such as a phone, tablet, computer or any other device that can
                    be used to visit SmileCare and use the services.
                </li>
                <li>
                    Service: refers to the service provided by SmileCare as described in the relative terms (if
                    available) and on this platform.
                </li>
                <li>
                    Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners,
                    and others who provide our content or whose products or services we think may interest you.
                </li>
                <li>Website: SmileCare's site, which can be accessed via this URL: https://dentscape.ai/SmileCare</li>
                <li>You: a person or entity that is registered with SmileCare to use the Services.</li>
            </ul>
            <p>
                This Terms & Conditions were created with{" "}
                <a href="https://termify.io" target="_blank" rel="noreferrer">
                    Termify.io
                </a>
                .
            </p>

            <h3>Restrictions</h3>
            <p>You agree not to, and you will not permit others to:</p>
            <ul>
                <li>
                    License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise
                    commercially exploit the website or make the platform available to any third party.
                </li>
                <li>
                    Modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part
                    of the website.
                </li>
                <li>
                    Remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of
                    SmileCare or its affiliates, partners, suppliers or the licensors of the website.
                </li>
            </ul>

            <h3>Return and Refund Policy</h3>
            <p>
                Thanks for shopping at SmileCare. We appreciate the fact that you like to buy the stuff we build. We
                also want to make sure you have a rewarding experience while you're exploring, evaluating, and
                purchasing our products.
            </p>
            <p>
                As with any shopping experience, there are terms and conditions that apply to transactions at SmileCare.
                We'll be as brief as our attorneys will allow. The main thing to remember is that by placing an order or
                making a purchase at SmileCare, you agree to the terms along with SmileCare's Privacy Policy.
            </p>
            <p>
                If, for any reason, You are not completely satisfied with any good or service that we provide, don't
                hesitate to contact us and we will discuss any of the issues you are going through with our product.
            </p>

            <h3>Your Suggestions</h3>
            <p>
                Any feedback, comments, ideas, improvements or suggestions (collectively, "Suggestions") provided by you
                to SmileCare with respect to the website shall remain the sole and exclusive property of SmileCare.
            </p>
            <p>
                SmileCare shall be free to use, copy, modify, publish, or redistribute the Suggestions for any purpose
                and in any way without any credit or any compensation to you.
            </p>

            <h3>Your Consent</h3>
            <p>
                We've updated our Terms & Conditions to provide you with complete transparency into what is being set
                when you visit our site and how it's being used. By using our website, registering an account, or making
                a purchase, you hereby consent to our Terms & Conditions.
            </p>

            <h3>Links to Other Websites</h3>
            <p>
                This Terms & Conditions applies only to the Services. The Services may contain links to other websites
                not operated or controlled by SmileCare. We are not responsible for the content, accuracy or opinions
                expressed in such websites, and such websites are not investigated, monitored or checked for accuracy or
                completeness by us. Please remember that when you use a link to go from the Services to another website,
                our Terms & Conditions are no longer in effect. Your browsing and interaction on any other website,
                including those that have a link on our platform, is subject to that website's own rules and policies.
                Such third parties may use their own cookies or other methods to collect information about you.
            </p>

            <h3>Cookies</h3>
            <p>
                SmileCare uses "Cookies" to identify the areas of our website that you have visited. A Cookie is a small
                piece of data stored on your computer or mobile device by your web browser. We use Cookies to enhance
                the performance and functionality of our website but are non-essential to their use. However, without
                these cookies, certain functionality like videos may become unavailable or you would be required to
                enter your login details every time you visit the website as we would not be able to remember that you
                had logged in previously. Most web browsers can be set to disable the use of Cookies. However, if you
                disable Cookies, you may not be able to access functionality on our website correctly or at all. We
                never place Personally Identifiable Information in Cookies.
            </p>

            <h3>Changes To Our Terms & Conditions</h3>
            <p>
                You acknowledge and agree that SmileCare may stop (permanently or temporarily) providing the Service (or
                any features within the Service) to you or to users generally at SmileCare's sole discretion, without
                prior notice to you. You may stop using the Service at any time. You do not need to specifically inform
                SmileCare when you stop using the Service. You acknowledge and agree that if SmileCare disables access
                to your account, you may be prevented from accessing the Service, your account details or any files or
                other materials which is contained in your account.
            </p>
            <p>
                If we decide to change our Terms & Conditions, we will post those changes on this page, and/or update
                the Terms & Conditions modification date below.
            </p>

            <h3>Modifications to Our website</h3>
            <p>
                SmileCare reserves the right to modify, suspend or discontinue, temporarily or permanently, the website
                or any service to which it connects, with or without notice and without liability to you.
            </p>

            <h3>Updates to Our website</h3>
            <p>
                SmileCare may from time to time provide enhancements or improvements to the features/ functionality of
                the website, which may include patches, bug fixes, updates, upgrades and other modifications
                ("Updates").
            </p>
            <p>
                Updates may modify or delete certain features and/or functionalities of the website. You agree that
                SmileCare has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any
                particular features and/or functionalities of the website to you.
            </p>
            <p>
                You further agree that all Updates will be (i) deemed to constitute an integral part of the website, and
                (ii) subject to the terms and conditions of this Agreement.
            </p>

            <h3>Third-Party Services</h3>
            <p>
                We may display, include or make available third-party content (including data, information, applications
                and other products services) or provide links to third-party websites or services ("Third- Party
                Services").
            </p>
            <p>
                You acknowledge and agree that SmileCare shall not be responsible for any Third-Party Services,
                including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency,
                quality or any other aspect thereof. SmileCare does not assume and shall not have any liability or
                responsibility to you or any other person or entity for any Third-Party Services.
            </p>
            <p>
                Third-Party Services and links thereto are provided solely as a convenience to you and you access and
                use them entirely at your own risk and subject to such third parties' terms and conditions.
            </p>

            <h3>Term and Termination</h3>
            <p>This Agreement shall remain in effect until terminated by you or SmileCare.</p>
            <p>
                SmileCare may, in its sole discretion, at any time and for any or no reason, suspend or terminate this
                Agreement with or without prior notice.
            </p>
            <p>
                This Agreement will terminate immediately, without prior notice from SmileCare, in the event that you
                fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting
                the website and all copies thereof from your computer.
            </p>
            <p>
                Upon termination of this Agreement, you shall cease all use of the website and delete all copies of the
                website from your computer.
            </p>
            <p>
                Termination of this Agreement will not limit any of SmileCare's rights or remedies at law or in equity
                in case of breach by you (during the term of this Agreement) of any of your obligations under the
                present Agreement.
            </p>

            <h3>Copyright Infringement Notice</h3>
            <p>
                If you are a copyright owner or such owner's agent and believe any material on our website constitutes
                an infringement on your copyright, please contact us setting forth the following information: (a) a
                physical or electronic signature of the copyright owner or a person authorized to act on his behalf; (b)
                identification of the material that is claimed to be infringing; (c) your contact information, including
                your address, telephone number, and an email; (d) a statement by you that you have a good faith belief
                that use of the material is not authorized by the copyright owners; and (e) the a statement that the
                information in the notification is accurate, and, under penalty of perjury you are authorized to act on
                behalf of the owner.
            </p>

            <h3>Indemnification</h3>
            <p>
                You agree to indemnify and hold SmileCare and its parents, subsidiaries, affiliates, officers,
                employees, agents, partners and licensors (if any) harmless from any claim or demand, including
                reasonable attorneys' fees, due to or arising out of your: (a) use of the website; (b) violation of this
                Agreement or any law or regulation; or (c) violation of any right of a third party.
            </p>

            <h3>No Warranties</h3>
            <p>
                The website is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without
                warranty of any kind. To the maximum extent permitted under applicable law, SmileCare, on its own behalf
                and on behalf of its affiliates and its and their respective licensors and service providers, expressly
                disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the website,
                including all implied warranties of merchantability, fitness for a particular purpose, title and
                non-infringement, and warranties that may arise out of course of dealing, course of performance, usage
                or trade practice. Without limitation to the foregoing, SmileCare provides no warranty or undertaking,
                and makes no representation of any kind that the website will meet your requirements, achieve any
                intended results, be compatible or work with any other software, websites, systems or services, operate
                without interruption, meet any performance or reliability standards or be error free or that any errors
                or defects can or will be corrected.
            </p>
            <p>
                Without limiting the foregoing, neither SmileCare nor any SmileCare's provider makes any representation
                or warranty of any kind, express or implied: (i) as to the operation or availability of the website, or
                the information, content, and materials or products included thereon; (ii) that the website will be
                uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or
                content provided through the website; or (iv) that the website, its servers, the content, or e-mails
                sent from or on behalf of SmileCare are free of viruses, scripts, trojan horses, worms, malware,
                timebombs or other harmful components.
            </p>
            <p>
                Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations
                on the applicable statutory rights of a consumer, so some or all of the above exclusions and limitations
                may not apply to you.
            </p>

            <h3>Limitation of Liability</h3>
            <p>
                Notwithstanding any damages that you might incur, the entire liability of SmileCare and any of its
                suppliers under any provision of this Agreement and your exclusive remedy for all of the foregoing shall
                be limited to the amount actually paid by you for the website.
            </p>
            <p>
                To the maximum extent permitted by applicable law, in no event shall SmileCare or its suppliers be
                liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not
                limited to, damages for loss of profits, for loss of data or other information, for business
                interruption, for personal injury, for loss of privacy arising out of or in any way related to the use
                of or inability to use the website, third-party software and/or third-party hardware used with the
                website, or otherwise in connection with any provision of this Agreement), even if SmileCare or any
                supplier has been advised of the possibility of such damages and even if the remedy fails of its
                essential purpose.
            </p>
            <p>
                Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential
                damages, so the above limitation or exclusion may not apply to you.
            </p>

            <h3>Severability</h3>
            <p>
                If any provision of this Agreement is held to be unenforceable or invalid, such provision will be
                changed and interpreted to accomplish the objectives of such provision to the greatest extent possible
                under applicable law and the remaining provisions will continue in full force and effect.
            </p>
            <p>
                This Agreement, together with the Privacy Policy and any other legal notices published by SmileCare on
                the Services, shall constitute the entire agreement between you and SmileCare concerning the Services.
                If any provision of this Agreement is deemed invalid by a court of competent jurisdiction, the
                invalidity of such provision shall not affect the validity of the remaining provisions of this
                Agreement, which shall remain in full force and effect. No waiver of any term of this Agreement shall be
                deemed a further or continuing waiver of such term or any other term, and SmileCare's failure to assert
                any right or provision under this Agreement shall not constitute a waiver of such right or provision.
                YOU AND SmileCare AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE
                WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY
                BARRED.
            </p>

            <h3>Waiver</h3>
            <p>
                Except as provided herein, the failure to exercise a right or to require performance of an obligation
                under this Agreement shall not effect a party's ability to exercise such right or require such
                performance at any time thereafter nor shall be the waiver of a breach constitute waiver of any
                subsequent breach.
            </p>
            <p>
                No failure to exercise, and no delay in exercising, on the part of either party, any right or any power
                under this Agreement shall operate as a waiver of that right or power. Nor shall any single or partial
                exercise of any right or power under this Agreement preclude further exercise of that or any other right
                granted herein. In the event of a conflict between this Agreement and any applicable purchase or other
                terms, the terms of this Agreement shall govern.
            </p>

            <h3>Amendments to this Agreement</h3>
            <p>
                SmileCare reserves the right, at its sole discretion, to modify or replace this Agreement at any time.
                If a revision is material we will provide at least 30 days' notice prior to any new terms taking effect.
                What constitutes a material change will be determined at our sole discretion.
            </p>
            <p>
                By continuing to access or use our website after any revisions become effective, you agree to be bound
                by the revised terms. If you do not agree to the new terms, you are no longer authorized to use
                SmileCare.
            </p>

            <h3>Entire Agreement</h3>
            <p>
                The Agreement constitutes the entire agreement between you and SmileCare regarding your use of the
                website and supersedes all prior and contemporaneous written or oral agreements between you and
                SmileCare.
            </p>
            <p>
                You may be subject to additional terms and conditions that apply when you use or purchase other
                SmileCare's services, which SmileCare will provide to you at the time of such use or purchase.
            </p>

            <h3>Updates to Our Terms</h3>
            <p>
                We may change our Service and policies, and we may need to make changes to these Terms so that they
                accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for
                example, through our Service) before we make changes to these Terms and give you an opportunity to
                review them before they go into effect. Then, if you continue to use the Service, you will be bound by
                the updated Terms. If you do not want to agree to these or any updated Terms, you can delete your
                account.
            </p>

            <h3>Intellectual Property</h3>
            <p>
                The website and its entire contents, features and functionality (including but not limited to all
                information, software, text, displays, images, video and audio, and the design, selection and
                arrangement thereof), are owned by SmileCare, its licensors or other providers of such material and are
                protected by Taiwan and international copyright, trademark, patent, trade secret and other intellectual
                property or proprietary rights laws. The material may not be copied, modified, reproduced, downloaded or
                distributed in any way, in whole or in part, without the express prior written permission of SmileCare,
                unless and except as is expressly provided in these Terms & Conditions. Any unauthorized use of the
                material is prohibited.
            </p>

            <h3>Agreement to Arbitrate</h3>
            <p>
                This section applies to any dispute EXCEPT IT DOESN'T INCLUDE A DISPUTE RELATING TO CLAIMS FOR
                INJUNCTIVE OR EQUITABLE RELIEF REGARDING THE ENFORCEMENT OR VALIDITY OF YOUR OR SmileCare’s INTELLECTUAL
                PROPERTY RIGHTS. The term “dispute” means any dispute, action, or other controversy between you and
                SmileCare concerning the Services or this agreement, whether in contract, warranty, tort, statute,
                regulation, ordinance, or any other legal or equitable basis. “Dispute” will be given the broadest
                possible meaning allowable under law.
            </p>

            <h3>Notice of Dispute</h3>
            <p>
                In the event of a dispute, you or SmileCare must give the other a Notice of Dispute, which is a written
                statement that sets forth the name, address, and contact information of the party giving it, the facts
                giving rise to the dispute, and the relief requested. You must send any Notice of Dispute via email to:
                info@dentscape.ai. SmileCare will send any Notice of Dispute to you by mail to your address if we have
                it, or otherwise to your email address. You and SmileCare will attempt to resolve any dispute through
                informal negotiation within sixty (60) days from the date the Notice of Dispute is sent. After sixty
                (60) days, you or SmileCare may commence arbitration.
            </p>

            <h3>Binding Arbitration</h3>
            <p>
                If you and SmileCare don't resolve any dispute by informal negotiation, any other effort to resolve the
                dispute will be conducted exclusively by binding arbitration as described in this section. You are
                giving up the right to litigate (or participate in as a party or class member) all disputes in court
                before a judge or jury. The dispute shall be settled by binding arbitration in accordance with the
                commercial arbitration rules of the American Arbitration Association. Either party may seek any interim
                or preliminary injunctive relief from any court of competent jurisdiction, as necessary to protect the
                party's rights or property pending the completion of arbitration. Any and all legal, accounting, and
                other costs, fees, and expenses incurred by the prevailing party shall be borne by the non-prevailing
                party.
            </p>

            <h3>Submissions and Privacy</h3>
            <p>
                In the event that you submit or post any ideas, creative suggestions, designs, photographs, information,
                advertisements, data or proposals, including ideas for new or improved products, services, features,
                technologies or promotions, you expressly agree that such submissions will automatically be treated as
                non-confidential and non-proprietary and will become the sole property of SmileCare without any
                compensation or credit to you whatsoever. SmileCare and its affiliates shall have no obligations with
                respect to such submissions or posts and may use the ideas contained in such submissions or posts for
                any purposes in any medium in perpetuity, including, but not limited to, developing, manufacturing, and
                marketing products and services using such ideas.
            </p>

            <h3>Promotions</h3>
            <p>
                SmileCare may, from time to time, include contests, promotions, sweepstakes, or other activities
                (“Promotions”) that require you to submit material or information concerning yourself. Please note that
                all Promotions may be governed by separate rules that may contain certain eligibility requirements, such
                as restrictions as to age and geographic location. You are responsible to read all Promotions rules to
                determine whether or not you are eligible to participate. If you enter any Promotion, you agree to abide
                by and to comply with all Promotions Rules. Additional terms and conditions may apply to purchases of
                goods or services on or through the Services, which terms and conditions are made a part of this
                Agreement by this reference.
            </p>

            <h3>Typographical Errors</h3>
            <p>
                In the event a product and/or service is listed at an incorrect price or with incorrect information due
                to typographical error, we shall have the right to refuse or cancel any orders placed for the product
                and/or service listed at the incorrect price. We shall have the right to refuse or cancel any such order
                whether or not the order has been confirmed and your credit card charged. If your credit card has
                already been charged for the purchase and your order is canceled, we shall immediately issue a credit to
                your credit card account or other payment account in the amount of the charge.
            </p>

            <h3>Miscellaneous</h3>
            <p>
                If for any reason a court of competent jurisdiction finds any provision or portion of these Terms &
                Conditions to be unenforceable, the remainder of these Terms & Conditions will continue in full force
                and effect. Any waiver of any provision of these Terms & Conditions will be effective only if in writing
                and signed by an authorized representative of SmileCare. SmileCare will be entitled to injunctive or
                other equitable relief (without the obligations of posting any bond or surety) in the event of any
                breach or anticipatory breach by you. SmileCare operates and controls the SmileCare Service from its
                offices in Taiwan. The Service is not intended for distribution to or use by any person or entity in any
                jurisdiction or country where such distribution or use would be contrary to law or regulation.
                Accordingly, those persons who choose to access the SmileCare Service from other locations do so on
                their own initiative and are solely responsible for compliance with local laws, if and to the extent
                local laws are applicable. These Terms & Conditions (which include and incorporate the SmileCare Privacy
                Policy) contains the entire understanding, and supersedes all prior understandings, between you and
                SmileCare concerning its subject matter, and cannot be changed or modified by you. The section headings
                used in this Agreement are for convenience only and will not be given any legal import.
            </p>

            <h3>Disclaimer</h3>
            <p>SmileCare is not responsible for any content, code or any other imprecision.</p>
            <p>SmileCare does not provide warranties or guarantees.</p>
            <p>
                In no event shall SmileCare be liable for any special, direct, indirect, consequential, or incidental
                damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising
                out of or in connection with the use of the Service or the contents of the Service. The Company reserves
                the right to make additions, deletions, or modifications to the contents on the Service at any time
                without prior notice.
            </p>
            <p>
                The SmileCare Service and its contents are provided "as is" and "as available" without any warranty or
                representations of any kind, whether express or implied. SmileCare is a distributor and not a publisher
                of the content supplied by third parties; as such, SmileCare exercises no editorial control over such
                content and makes no warranty or representation as to the accuracy, reliability or currency of any
                information, content, service or merchandise provided through or accessible via the SmileCare Service.
                Without limiting the foregoing, SmileCare specifically disclaims all warranties and representations in
                any content transmitted on or in connection with the SmileCare Service or on sites that may appear as
                links on the SmileCare Service, or in the products provided as a part of, or otherwise in connection
                with, the SmileCare Service, including without limitation any warranties of merchantability, fitness for
                a particular purpose or non-infringement of third party rights. No oral advice or written information
                given by SmileCare or any of its affiliates, employees, officers, directors, agents, or the like will
                create a warranty. Price and availability information is subject to change without notice. Without
                limiting the foregoing, SmileCare does not warrant that the SmileCare Service will be uninterrupted,
                uncorrupted, timely, or error-free.
            </p>

            <h3>Contact Us</h3>
            <p>Don't hesitate to contact us if you have any questions.</p>
            <ul>
                <li>
                    Via Email:{" "}
                    <a
                        href="mailto:info@dentscape.ai"
                        className="__cf_email__"
                        data-cfemail="47342235312e242207373522332222332f6924282a"
                    >
                        info@dentscape.ai
                    </a>
                </li>
            </ul>
        </>
    );

    return <div className="termsBg">{type !== "SmileCare" ? <PreteethAIPro /> : <SmileCare />}</div>;
}
